import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Checkbox,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
} from "@mui/material";
// components

import Label from "../../../components/Label";

import Iconify from "../../../components/iconify";
import { TableMoreMenu } from "../../../components/table";

import { useDispatch } from "react-redux";

import { getUserId } from "../../../redux/userSlice";

// ----------------------------------------------------------------------

UserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function UserTableRow({
  row,
  selected,
  handlDisable,
  handlEnable,
  onSelectRow,
  handlDelete,
  handleOpenDialog,
}) {
  const theme = useTheme();

  let navigate = useNavigate();

  const dispatch = useDispatch();

  const { firstName, lastName, city } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding='checkbox'>
				<Checkbox checked={selected} onClick={onSelectRow} />
			</TableCell> */}

      <TableCell sx={{ display: "flex", alignItems: "center" }}>
        <Avatar alt={"avatar"} src={""} sx={{ mr: 2 }} />
        <Typography variant="subtitle2" noWrap>
          {firstName + " " + lastName}
        </Typography>
      </TableCell>

      <TableCell align="left">{row?.user?.email}</TableCell>

      <TableCell align="left">{city}</TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        {row?.user?.role}
      </TableCell>

      <TableCell align="center">
        <Iconify
          icon={
            row?.user?.verified
              ? "eva:checkmark-circle-fill"
              : "eva:clock-outline"
          }
          sx={{
            width: 20,
            height: 20,
            color: "success.main",
            ...(!row?.user?.verified && { color: "warning.main" }),
          }}
        />
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={(row?.user?.isEnable === false && "error") || "success"}
          sx={{ textTransform: "capitalize" }}
        >
          {row?.user?.isEnable === false ? "Banned" : "Active"}
        </Label>
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  handleOpenDialog(row?.user?._id);
                  handleCloseMenu();
                }}
                sx={{ color: "error.main" }}
              >
                <Iconify icon={"eva:trash-2-outline"} />
                Delete
              </MenuItem>

              <MenuItem
                onClick={() => {
                  dispatch(getUserId(row?.user?._id));
                  handleCloseMenu();
                  navigate(`/dashboard/user/profile/edit/${row?.user?._id}`);
                }}
              >
                <Iconify icon={"eva:edit-fill"} />
                Edit
              </MenuItem>

              <MenuItem
                onClick={() => {
                  if (row?.user?.isEnable === true) {
                    handlDisable(row?.user?._id);
                    handleCloseMenu();
                  } else {
                    handlEnable(row?.user?._id);
                    handleCloseMenu();
                  }
                }}
              >
                <Iconify icon={"eva:person-delete-fill"} />
                {row?.user?.isEnable === true ? "Disable" : "Enable"}
              </MenuItem>

              <MenuItem
                onClick={() => {
                  dispatch(getUserId(row?.user?._id));
                  handleCloseMenu();

                  navigate(`/dashboard/user/profile/${row?.user?._id}`);
                }}
              >
                <Iconify icon={"bx:detail"} />
                Detail
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
