import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	minHeight: '100vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ConnectSuccess() {
	return (
		<>
			<Helmet>
				<title> Connected successfully</title>
			</Helmet>

			<Container>
				<StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
					<Typography variant='h3' paragraph>
						Your account has been connected successful!
					</Typography>

					<Box
						component='img'
						src='/assets/images/payment.png'
						sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
					/>
				</StyledContent>
			</Container>
		</>
	);
}
